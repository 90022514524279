import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import "../stylesheets/Inscription.scss"
import { Formik } from 'formik'
import * as yup from "yup"
import Axios from 'axios'


export default function Inscription(props) {
    const { history } = props

    const [accountSuccessful, setAccountSuccessful] = useState(false)

    const schema = yup.object().shape({
        username: yup.string().required("Nom d'utilisateur"),
        email: yup.string().email("Email invalide").required("Email est nécéssaire"),
        password: yup.string()
            .required("Mot de passe requis").min(8, "Le mot de passe doit avoir au moins 8 caractères"),
        confirmpassword: yup.string().required("Confirmation du mot de passe requis!").oneOf([yup.ref("password"), null], "Les mots de passe doivent être identique")
    })

    const onSubmit = values => {
        const data = {
            email: values.email,
            password: values.password,
            name: values.username
        }

        Axios.post("/users", data, 
        {
            Accept: "*/*",
            "Content-Type": "application/json",
        }).then(response => {
            if(response.data) {
                setAccountSuccessful(true)
                setTimeout(() => {
                    handleClose()
                    setTimeout(() => history.push("/jouer"), 500)
                }, 5000)
            }
        })
    }

    const handleClose = e => {
        const { functionCallFromParent } = props
        functionCallFromParent()
    }
    
    return (
        <div>
            <h1>S'inscrire</h1>
            
            {accountSuccessful ? (
                <div className="toonCard">
                    <h1>Votre compte créé avec succès!</h1>
                    <p>
                        Vous y êtes presque!
                        Télécharger le lanceur sur notre site et préparez vous pour une aventure Toontastique dans le monde de Toontown!
                    </p>
                </div>
            ) : (
                <Formik
                validationSchema = {schema}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues = {{
                    username: "",
                    email: "",
                    password: "",
                    confirmpassword: ""
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false)
                    onSubmit(values)
                }
            }>
                {({ handleSubmit, handleChange, values, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Control
                                name="username"
                                type="text"
                                placeholder="Nom d'utilisateur"
                                value={values.username}
                                onChange={handleChange}
                            />
                            <span className="error" style={{ color: "red" }}>
                                {errors.username}    
                            </span>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                name="email"
                                type="text"
                                placeholder="E-mail"
                                value={values.email}
                                onChange={handleChange}
                            />
                            <span className="error" style={{ color: "red" }}>
                                {errors.email}    
                            </span>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                name="password"
                                type="password"
                                placeholder="Mot de passe"
                                value={values.password}
                                onChange={handleChange}
                            />
                            <span className="error" style={{ color: "red" }}>
                                {errors.password}    
                            </span>
                        </Form.Group>
                        
                        <Form.Group>
                            <Form.Control
                                name="confirmpassword"
                                type="password"
                                placeholder="Validé le mot de passe"
                                value={values.confirmpassword}
                                onChange={handleChange}
                            />
                            <span className="error" style={{ color: "red" }}>
                                {errors.confirmpassword}
                            </span>
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            S'inscrire!
                        </Button>
                    </Form>
                )}
            </Formik>
            )}
        </div>
    )
}
