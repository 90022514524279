import React from 'react'
import { Form, Button } from 'react-bootstrap'
import "../stylesheets/Inscription.scss"

export default function Connecter() {
    return (
        <div>
            <h1>Se connecter</h1>
            <Form onSubmit={() => console.log("bruh")}>
                <Form.Group>
                    <Form.Control
                    name="username"
                    type="text"
                    placeholder="Nom d'utilisateur"
                    value={""}
                    onChange={() => console.log("bruh")}
                    required
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Control
                    name="password"
                    type="password"
                    placeholder="Mot de passe"
                    value={""}
                    onChange={() => console.log("bruh")}
                    required
                    />
                </Form.Group>
                
                <Button variant="primary" type="submit">
                    Se connecter!
                </Button>
            </Form>
        </div>
    )
}
