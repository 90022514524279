import React from 'react'
import img2 from "../components/img/toontownfrenchy.jpg"
import "../stylesheets/MiniNews.scss"
import { Link } from 'react-router-dom'


export default function MiniNews() {
    return (
        <div className="content--mininews">
			<div>
				<span className="newsHead">LES NEWS</span>
				<p>Retrouver ici les dernières nouveautés pour Toontown Frenchy!</p>
			</div>
			<div className="toonCard toonCard--miniNews desktop">
				<div>
					<img className="newsImg" src={img2} alt="ttfr-news" />
				</div>

				<div className="newsContent">
					<h1>Toontown Frenchy réouvre!</h1>
					<p>Après tant d'absence, l'unique et seul serveur Français et Francophone de Toontown est bientôt de retour, pour le plus grand plaisir de tous !</p>
					<p>⠀</p>
					<Link to="/" className="newsLink">En construction ...</Link>
				</div>
			</div>
        </div>
    )
}
