import React from 'react';
import { BrowserRouter, Route, withRouter } from 'react-router-dom';
import './App.scss';
import "bootstrap/dist/css/bootstrap.css"
import Navigation from './components/Navigation';
import Acceuil from './components/Acceuil';
import Footer from './components/Footer';
import Jouer from './components/Jouer';

const HeaderWithRouter = withRouter(Navigation)

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <HeaderWithRouter />
        <Route exact path="/" component={Acceuil} />
        <Route exact path="/jouer" component={Jouer} />
      </BrowserRouter>
      <Footer />
    </div>
  );
}
