import React from 'react'
import { Table, Container, Row, Col } from 'react-bootstrap'
import MiniNews from './MiniNews'
import "../stylesheets/Jouer.scss"
import Toon2 from "./img/toon2.png"

export default function Jouer() {
    return (
        <div className="content content--jouer">
			<div className="toonCard toonCard--play">
                <Container>
                    <Row className="infoDlTop">
                        <Col lg="2" className="disparais"/>
                        <Col lg="7" md="12">
                            <div className="telecharger">
                                <h1>Bienvenue dans Toontown Frenchy!</h1>
                                <p>Te voilà à quelque instant de pouvoir jouer a Toontown Frenchy!</p>
                                <p>
                                    Une fois le lanceur d'installer, connecte toi à ton compte Toontown Frenchy sur le lanceur et 
                                    le lanceur installera tout les fichiers nécessaire pour pouvoir jouer!
                                </p>
                                <div className="dl">
                                    <a className="button button-telecharger" href="https://toontownfrenchy.fr/download/FrenchySetup.exe">Installer le Lanceur!</a>
                                </div>
                            </div>
                        </Col>
                        <img src={Toon2} className="toonPlay" alt="Toon1" /> 
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="patchNote">
                                <h1>Notes de mise à jour</h1>
                                <h1>Bêta V1.0</h1>
                                <blockquote>
                                    <u><b>Général</b></u><br/>
                                    • Les notes de mise à jour seront bientôt ici <br />
                                    <br />
                                </blockquote>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="requirements" md="5">
                            <h1>Minimum</h1>
                            <Table responsive>
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th>Windows</th>
                                        <th>Mac</th>
                                        <th>Linux</th>
                                    </tr>
                                    <tr>
                                        <th>Système d'exploitation</th>
                                        <td>Microsoft Windows 7</td>
                                        <td>Bientôt!</td>
                                        <td>Bientôt!</td>
                                    </tr>
                                    <tr>
                                        <th>CPU</th>
                                        <td colspan="3">CPU dual-core</td>
                                    </tr>
                                    <tr>
                                        <th>RAM</th>
                                        <td colspan="3">2 Go de RAM</td>
                                    </tr>
                                    <tr>
                                        <th>Disque Dur</th>
                                        <td colspan="3">Au moins 1200 Mo de libre </td>
                                    </tr>
                                    <tr>
                                        <th>Carte Graphique</th>
                                        <td colspan="3">Carte compatible avec OpenGL avec au moins 256 Mo de mémoire Graphique</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col md="1" />
                        <Col md="5">
                            <h1>Recommandé</h1>
                            <Table responsive>
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th>Windows</th>
                                        <th>Mac</th>
                                        <th>Linux</th>
                                    </tr>
                                    <tr>
                                        <th>Système d'exploitation</th>
                                        <td>Microsoft Windows 10</td>
                                        <td>Bientôt!</td>
                                        <td>Bientôt!</td>
                                    </tr>
                                    <tr>
                                        <th>CPU</th>
                                        <td colspan="3">CPU 64-bit quad-core</td>
                                    </tr>
                                    <tr>
                                        <th>RAM</th>
                                        <td colspan="3">4 Go de RAM</td>
                                    </tr>
                                    <tr>
                                        <th>Disque Dur</th>
                                        <td colspan="3">Au moins 3 Go de libre </td>
                                    </tr>
                                    <tr>
                                        <th>Carte Graphique</th>
                                        <td colspan="3">Carte compatible avec OpenGL avec au moins 1 Go de mémoire Graphique</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <MiniNews />
            </div>
        </div>
    )
}
