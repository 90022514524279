import React from 'react'
import MiniNews from './MiniNews'
import Logo from "./img/toontown-logo.png"
import Toon1 from "./img/toon.png"
import "../stylesheets/Acceuil.scss"
import { Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Acceuil() {
    return (
        <>
            <div className="content content--acceuil">
                <div className="logo">
                    <img src={Logo} alt="Logo" />
                </div>
            </div>
            <div className="content content--acceuil">
                <Container>
                    <Row>
                        <Col md="3">
                            <img src={Toon1} className="toonImgAcceuil" alt="Toon1" /> 
                        </Col>
                        <Col className="toonCard toonCard--infoFr ">
                            <div className="infoFrText">
                                <h1>Toontown Frenchy c'est quoi?</h1>
                                <p>Toontown Frenchy est l'unique et seul serveur Français et Francophone de Toontown Online!</p>
                                <p>Rejoignez nous maintenant sur la Bêta en créant un compte sur notre site et en téléchargeant le jeu en cliquant sur le bouton JOUER! </p>
                            </div>
                            <Link to="/jouer">
                                <button className="button button-jouer">Jouer!</button>
                            </Link>
                            {/* <a href="https://toontownfrenchy.fr/download/FrenchySetup.exe"className="button button-jouer">Jouer! </a> */}
                        </Col>
                    </Row>
                </Container>
            </div>
           <div className="content">
               <MiniNews />
           </div>
        </>
    )
}
