import React, { useState } from 'react'
import { Navbar, Nav, Modal } from "react-bootstrap"
import { NavLink } from 'react-router-dom'
import Inscription from './Inscription'
import Connecter from './Connecter'
import Logo from "./img/toontown-logo-ultrasmall.png"
import Eyes from "./img/toontown.ico"
import Jouer from "./img/jouer.png"
import "../stylesheets/Navigation.scss"

export default function Navigation(props) {
    const { history } = props

    const [showInscription, setShowInscription] = useState(false)
    const [showLogin, setShowLogin] = useState(false)


    const handleClose = () => {
        setShowInscription(false)
        setShowLogin(false)
    }

    const handleShow = type => {
        switch(type) {
            case "inscription": 
                setShowInscription(true)
                break
            case "login": 
                setShowLogin(true)
                break
            default:
                setShowLogin(false)
                setShowInscription(false)
        }
    }

    return (
        <>
            <div className="nav-container">
                <Navbar collapseOnSelect expand="lg" variant="frenchy">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav">
                        <img className="eyes" src={Eyes} alt="Eyes" />    
                    </Navbar.Toggle> 
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link as={NavLink} className="imgLogo desktop" activeClassName="activePage" exact to="/">
                                <img src={Logo} alt="Logo" />
                            </Nav.Link>
                            {/* <Nav.Link as={NavLink} activeClassName="activePage" to="/news">News</Nav.Link> */}
                            <Nav.Link as={NavLink} activeClassName="activePage" className="mobile" to="/jouer">Jouer</Nav.Link>
                            <Nav.Link onClick={() => handleShow("inscription")}>S'inscrire</Nav.Link>
                            {/* <Nav.Link onClick={() => handleShow("login")}>Se connecter</Nav.Link> */}
                        </Nav>
                        <Nav className="justify-content-end desktop">
                            <Nav.Link as={NavLink} className="imgJouer" to="/jouer">
                                <img src={Jouer} alt="Logo" />
                            </Nav.Link>
                            {/* <a href="https://toontownfrenchy.fr/download/FrenchySetup.exe" className="imgJouer"><img src={Jouer} alt="Logo" /> </a> */}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <Modal dialogClassName="toonCard toonCard--account" show={showInscription} onHide={handleClose}>
                <Inscription functionCallFromParent={handleClose} history={history} /> 
            </Modal>
            <Modal dialogClassName="toonCard toonCard--account" show={showLogin} onHide={handleClose}>
                <Connecter /> 
            </Modal>
        </>
    )
}
