import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faDiscord } from "@fortawesome/free-brands-svg-icons"
import "../stylesheets/Footer.scss"

export default function Footer() {
    return (
        <div className="footer">
            <Container>
                <Row>
                    <Col>
                        <p className="icons">
                            <a href="https://discord.gg/2wANCeb">
                                <FontAwesomeIcon size='5x' icon={faDiscord} />
                            </a>
                            {/* <FontAwesomeIcon size='5x' icon={faTwitter} /> */}
                        </p>
                    </Col>
                </Row>
                {/* <Row>
                    <Col>1 of 1</Col>
                </Row>
                <Row>
                    <Col>1 of 1</Col>
                </Row> */}
                <Row>
                    <Col>
                        Toontown Frenchy est un jeu entièrement gratuit financé uniquement par l'équipe. 
                        Toontown Frenchy ne génère aucun revenue d'aucune sorte et nous sommes en aucun cas affiliés à Walt Disney Co ou à des filiales.
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
